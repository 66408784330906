<template>
  <div>
    <AdminLocationClosureForm
      :closure="closure"
      @submit="saveClosure"
      :title="'Modify Park Closure'"
    />
  </div>
</template>

<script>
import AdminClosureService from "@/services/admin/AdminClosureService.js";
import AdminLocationClosureForm from "@/components/admin/AdminLocationClosureForm";
import moment from "moment";

export default {
  name: "AdminLocationClosureModify",
  title: "Admin - Location Closure Modify",
  props: {
    closureId: {
      type: String,
      default: ""
    }
  },
  components: {
    AdminLocationClosureForm
  },
  data() {
    return {
      closure: {}
    };
  },
  methods: {
    async getClosure() {
      const closureService = new AdminClosureService(this.tenantId);
      const response = await closureService.getClosure(this.closureId);
      this.closure = {
        id: response.data.id,
        startDate: moment(response.data.startDate, "YYYY-MM-DDThh:mm:ss.SSSZ")
          .utc()
          .format("MM/DD/YYYY"),
        endDate: moment(response.data.endDate, "YYYY-MM-DDThh:mm:ss.SSSZ")
          .utc()
          .format("MM/DD/YYYY"),
        isDeleted: response.data.isDeleted,
        dateDeleted: response.data.dateDeleted,
        closureTypeId: response.data.closureTypeId,
        closureTypeName: response.data.closureTypeName,
        closureSpots: response.data.closureSpots,
        closureSpotIds: response.data.closureSpots.map(x => x.id),
        includeSunday: response.data.includeSunday,
        includeMonday: response.data.includeMonday,
        includeTuesday: response.data.includeTuesday,
        includeWednesday: response.data.includeWednesday,
        includeThursday: response.data.includeThursday,
        includeFriday: response.data.includeFriday,
        includeSaturday: response.data.includeSaturday,
        isRecurring: response.data.isRecurring,
        overrideAllowed: response.data.overrideAllowed
      };
    },
    saveClosure(request) {
      let closureService = new AdminClosureService(this.tenantId);
      closureService.updateClosure(request).then(response => {
        if (response?.statusCode == "Success") {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-success",
            message: "Park Closure had been Modified.",
            layer: "admin"
          });
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.$router.push("/admin/park-closure-search").catch(() => {});
          }, 3000);
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      });
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  created() {
    this.getClosure();
  }
};
</script>

<style></style>
