<template>
  <div class="card">
    <div class="card-header">
      <a @click="toggleExpansion">
        <h4 class="card-title mt-2 mb-0">
          Closure Notes
          <i class="fa float-right" :class="icon"></i>
        </h4>
      </a>
    </div>
    <ValidationObserver ref="commentSave" disabled>
      <div class="card-body" v-if="this.showComments">
        <div class="row">
          <div class="col-12">
            <ValidationProvider
              name="Comments"
              rules="max:9000|required"
              v-slot="{ errors, ariaInput, ariaMsg }"
            >
              <label
                @click="$refs.select.focus()"
                :class="{ error: errors[0] }"
                for="closureComments"
                >Comments</label
              >
              <button
                type="button"
                class="btn btn-primary mb-2 float-right"
                @click="addComment"
                v-if="closure.id"
              >
                Add Comment +
              </button>
              <textarea
                class="form-control form-control-lg mb-2"
                id="closureComments"
                rows="4"
                v-model="closure.comment"
                v-bind="ariaInput"
              ></textarea>
              <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                <li
                  v-for="(error, index) in errors"
                  :key="index"
                  class="error"
                  v-bind="ariaMsg"
                >
                  {{ error }}
                </li>
              </ul>
            </ValidationProvider>
            <span class="footer"
              >Characters remaining: {{ 9000 - comment.length }}</span
            >
          </div>
        </div>
        <div class="d-flex row mt-2">
          <div class="col-lg-12">
            <div>
              <b-table
                ref="table"
                striped
                :fields="fields"
                :items="comments"
                stacked="md"
                no-local-sorting
                small
                :busy="commentsLoading"
                bordered
                sort-icon-left
              >
                <template #table-busy>
                  <div class="text-center my-2">
                    <span
                      class="spinner-border spinner-border-sm align-middle"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import AdminClosureService from "@/services/admin/AdminClosureService.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import moment from "moment";

export default {
  name: "AdminClosureComments",
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    closure: Object
  },
  data() {
    return {
      comments: [],
      errors: [],
      comment: "",
      showComments: false,
      fields: [
        {
          key: "dateCreated",
          formatter: "formatDate",
          label: "Date Added",
          class: "dateColumn"
        },
        { key: "comment" },
        { key: "createdBy", label: "Username", class: "userColumn" }
      ],
      commentsLoading: false
    };
  },
  computed: {
    icon() {
      if (this.showComments) {
        return "fa-angle-down";
      }
      return "fa-angle-up";
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    toggleExpansion() {
      this.showComments = !this.showComments;
      if (this.showComments) {
        this.loadComments();
      }
    },
    async loadComments() {
      if (!this.closure.id) return;

      this.commentsLoading = true;
      const service = new AdminClosureService(this.tenantId);
      if (this.closure.id) {
        let response = await service.getClosureComments(this.closure.id);
        if (response?.statusCode == "Success") {
          response.data.forEach(
            x =>
              (x.dateCreated = moment(
                x.dateCreated,
                "YYYY-MM-DDThh:mm:ss.SSSZ"
              ).format("MM/DD/YYYY hh:mm A"))
          );
          this.comments = response.data;
          this.commentsLoading = false;
        } else {
          window.scrollTo(0, 0);
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      }
    },
    addComment() {
      this.$refs.commentSave.validate().then(async success => {
        if (success) {
          this.commentsLoading = true;
          const service = new AdminClosureService(this.tenantId);
          let response = await service.addClosureComment(
            this.closure.id,
            this.closure.comment
          );
          if (response?.statusCode == "Success") {
            this.closure.comment = "";
            response.data.dateCreated = moment(
              response.data.dateCreated,
              "YYYY-MM-DDThh:mm:ss.SSSZ"
            ).format("MM/DD/YYYY hh:mm A");
            this.comments.unshift(response.data);
            this.commentsLoading = false;
            this.$refs.commentSave.reset();
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      });
    }
  },
  mounted() {
    if (!this.closure.id) {
      this.toggleExpansion();
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style scoped>
.footer {
  font-size: 0.8em;
}
.success {
  vertical-align: middle;
  color: green;
}
</style>
<style>
.dateColumn {
  width: 200px !important;
}
.userColumn {
  width: 400px !important;
}
</style>
