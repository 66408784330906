<template>
  <div>
    <h3>
      Park Closures
      <hr />
    </h3>
    <div class="card" v-if="!loading">
      <div class="card-header">
        <div class="card-title mb-0">{{ title }}</div>
      </div>
      <div class="card-body">
        <ValidationObserver ref="parkClosureForm">
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.parkClosureForm"
          />
          <form>
            <div class="row mb-2">
              <div class="col-md-2 col-sm-12">
                <TextInput
                  name="Reference ID"
                  id="refId"
                  :disabled="true"
                  v-model="referenceId"
                />
              </div>
              <div class="col-md-3 col-sm-12">
                <DateInput
                  vid="startDate"
                  rules="required|isDate"
                  name="Closed From"
                  id="startDate"
                  v-model="parentClosure.startDate"
                />
                <small class="form-text text-muted mb-2"
                  >Start (effective) date of the closure.</small
                >
              </div>
              <div class="col-md-3 col-sm-12">
                <DateInput
                  vid="endDate"
                  rules="required|isDate|departureDateMinusOne:@startDate"
                  name="Closed To"
                  :dateDisabledFn="minDepartureDate"
                  :initialDate="initialDate"
                  id="endDate"
                  v-model="parentClosure.endDate"
                />
                <small class="form-text text-muted mb-2"
                  >End date of the closure</small
                >
              </div>
              <div class="col-md-2 col-sm-12">
                <div class="d-flex align-items-center h-100">
                  <div>
                    <input
                      type="checkbox"
                      class="mr-2"
                      id="overrideAllowed"
                      v-model="parentClosure.overrideAllowed"
                    />
                    <label class="form-check-label" for="overrideAllowed"
                      >Allow admin override reservations</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-sm-12">
                <div class="d-flex align-items-center h-100">
                  <div>
                    <input
                      type="checkbox"
                      class="mr-2"
                      id="isRecurring"
                      v-model="parentClosure.isRecurring"
                    />
                    <label class="form-check-label" for="isRecurring"
                      >Repeat Annually</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 col-sm-12">
                <label for="closureDaysOfWeek"
                  >Closure Days <span class="error">*</span></label
                >
                <div class="d-flex align-items-center" id="closureDaysOfWeek">
                  <div class="grow list-item">
                    <div class="py-1 text-center">
                      <p class="m-0 font-twelve" id="Sunday">Sun</p>
                      <div class="form-check form-check-inline m-0">
                        <input
                          type="checkbox"
                          id="includeSunday"
                          aria-labelledby="closureDaysOfWeek Sunday"
                          v-model="parentClosure.includeSunday"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grow list-item">
                    <div class="py-1 text-center">
                      <p class="m-0 font-twelve" id="Monday">Mon</p>
                      <div class="form-check form-check-inline m-0">
                        <input
                          type="checkbox"
                          id="includeMonday"
                          aria-labelledby="closureDaysOfWeek Monday"
                          v-model="parentClosure.includeMonday"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grow list-item">
                    <div class="py-1 text-center">
                      <p class="m-0 font-twelve" id="Tuesday">Tue</p>
                      <div class="form-check form-check-inline m-0">
                        <input
                          type="checkbox"
                          id="includeTuesday"
                          aria-labelledby="closureDaysOfWeek Tuesday"
                          v-model="parentClosure.includeTuesday"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grow list-item">
                    <div class="py-1 text-center">
                      <p class="m-0 font-twelve" id="Wednesday">Wed</p>
                      <div class="form-check form-check-inline m-0">
                        <input
                          type="checkbox"
                          id="includeWednesday"
                          aria-labelledby="closureDaysOfWeek Wednesday"
                          v-model="parentClosure.includeWednesday"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grow list-item">
                    <div class="py-1 text-center">
                      <p class="m-0 font-twelve" id="Thursday">Thu</p>
                      <div class="form-check form-check-inline m-0">
                        <input
                          type="checkbox"
                          id="includeThursday"
                          aria-labelledby="closureDaysOfWeek Thursday"
                          v-model="parentClosure.includeThursday"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grow list-item">
                    <div class="py-1 text-center">
                      <p class="m-0 font-twelve" id="Friday">Fri</p>
                      <div class="form-check form-check-inline m-0">
                        <input
                          type="checkbox"
                          id="includeFriday"
                          aria-labelledby="closureDaysOfWeek Friday"
                          v-model="parentClosure.includeFriday"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grow list-item">
                    <div class="py-1 text-center">
                      <p class="m-0 font-twelve" id="Saturday">Sat</p>
                      <div class="form-check form-check-inline m-0">
                        <input
                          type="checkbox"
                          id="includeSaturday"
                          aria-labelledby="closureDaysOfWeek Saturday"
                          v-model="parentClosure.includeSaturday"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <small class="form-text text-muted mb-2">
                  Days of the week associated with the selected park(s) and
                  site(s) closure.
                </small>
              </div>
              <div class="col-md-4 col-sm-12">
                <ValidationProvider
                  name="Closure Reason"
                  rules="required"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label :class="{ error: errors[0] }" for="closureType"
                    >Closure Reason <span class="error">*</span></label
                  >
                  <multiselect
                    v-model="parentClosure.closureType"
                    label="name"
                    track-by="id"
                    :multiple="false"
                    id="closureType"
                    ref="closureType"
                    :clear-on-select="false"
                    :preserve-search="true"
                    open-direction="bottom"
                    placeholder="Select Closure Reason"
                    :options="closureTypes"
                    v-bind="ariaInput"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="multiselect__tag" :key="option.id">
                        <span>{{ option.name }}</span>
                        <i
                          tabindex="1"
                          @keypress.enter.prevent="remove(option)"
                          @mousedown.prevent="remove(option)"
                          class="multiselect__tag-icon"
                        ></i>
                      </span>
                    </template>
                  </multiselect>

                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <ValidationProvider
                  name="Regions"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label :class="{ error: errors[0] }" for="closureRegions">
                    Regions
                  </label>
                  <multiselect
                    v-model="selectedRegions"
                    label="name"
                    track-by="id"
                    :multiple="true"
                    id="closureRegions"
                    ref="closureRegions"
                    :clear-on-select="false"
                    :preserve-search="true"
                    open-direction="bottom"
                    placeholder="Select Region(s)"
                    :options="regions"
                    v-bind="ariaInput"
                    @select="filterParksAdd"
                    @remove="filterParksRemove"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="multiselect__tag" :key="option.id">
                        <span>{{ option.name }}</span>
                        <i
                          tabindex="1"
                          @keypress.enter.prevent="remove(option)"
                          @mousedown.prevent="remove(option)"
                          class="multiselect__tag-icon"
                        ></i>
                      </span>
                    </template>
                  </multiselect>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2">
                  Selecting a region will select all parks associated with that
                  region. Removing a region will remove all parks of that region
                  from the list of selected parks.
                </small>
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="selectAllParksAndRegions"
                >
                  SELECT ALL
                </button>
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="deselectAllParksAndRegions"
                >
                  DESELECT ALL
                </button>
              </div>
              <div class="col-md-8 col-sm-12">
                <ValidationProvider
                  name="Parks"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                  rules="required"
                >
                  <label :class="{ error: errors[0] }" for="closureParks">
                    Parks
                    <span class="error">*</span>
                  </label>
                  <multiselect
                    v-model="selectedParks"
                    label="name"
                    track-by="id"
                    :multiple="true"
                    id="closureParks"
                    ref="closureParks"
                    :clear-on-select="false"
                    :preserve-search="true"
                    open-direction="bottom"
                    placeholder="Select Park(s)"
                    @input="openedSpots"
                    @open="openedParks"
                    :loading="loadingParks"
                    :options="parks"
                    v-bind="ariaInput"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="multiselect__tag" :key="option.id">
                        <span>{{ option.name }}</span>
                        <i
                          tabindex="1"
                          @keypress.enter.prevent="remove(option)"
                          @mousedown.prevent="remove(option)"
                          class="multiselect__tag-icon"
                        ></i>
                      </span>
                    </template>
                  </multiselect>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2">
                  Selecting a park will load all spots available for that park
                  below. Removing a park will remove all spots associated with
                  that park from the list of available spots.
                </small>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <ValidationProvider
                  name="Spot Types"
                  v-slot="{ errors, ariaInput, ariaMsg }"
                >
                  <label :class="{ error: errors[0] }" for="closureSpotTypes">
                    Spot Types
                  </label>
                  <multiselect
                    v-model="selectedSpotTypes"
                    label="name"
                    track-by="id"
                    :multiple="true"
                    id="closureSpotTypes"
                    ref="closureSpotTypes"
                    :clear-on-select="false"
                    :preserve-search="true"
                    open-direction="bottom"
                    placeholder="Select Spot Type(s)"
                    :options="spotTypes"
                    v-bind="ariaInput"
                    :disabled="parkNotSelected"
                    @open="openedSpotTypes"
                    @select="filterSpotsAdd"
                    @remove="filterSpotsRemove"
                  >
                    <template slot="tag" slot-scope="{ option, remove }">
                      <span class="multiselect__tag" :key="option.id">
                        <span>{{ option.name }}</span>
                        <i
                          tabindex="1"
                          @keypress.enter.prevent="remove(option)"
                          @mousedown.prevent="remove(option)"
                          class="multiselect__tag-icon"
                        ></i>
                      </span>
                    </template>
                  </multiselect>
                  <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                    <li
                      v-for="(error, index) in errors"
                      :key="index"
                      class="error"
                      v-bind="ariaMsg"
                    >
                      {{ error }}
                    </li>
                  </ul>
                </ValidationProvider>
                <small class="form-text text-muted mb-2">
                  Selecting a spot type will select all spots associated with
                  that spot type. Removing a spot type will remove spots
                  associated with that spot type from the list of selected
                  spots.
                </small>
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="selectAllSpotsAndSpotTypes"
                  :disabled="parkNotSelected"
                >
                  SELECT ALL
                </button>
                <button
                  type="button"
                  class="btn btn-primary mr-2 my-1"
                  @click="deselectAllSpotsAndSpotTypes"
                  :disabled="parkNotSelected"
                >
                  DESELECT ALL
                </button>
              </div>
              <div class="col-md-8 col-sm-12">
                <div class="row">
                  <div class="col-12">
                    <label for="filterInput"
                      >{{ spots.filter(x => x.isSelected).length }} of
                      {{ spots.length }} total spots selected</label
                    >
                    <div class="d-flex">
                      <div class="grow">
                        <b-form-input
                          v-model="filter"
                          type="search"
                          id="filterInput"
                          placeholder="Search Spots..."
                          class="mb-3"
                          :disabled="parkNotSelected"
                        ></b-form-input>
                      </div>
                      <div>
                        <button
                          type="button"
                          @click="filter = ''"
                          class="btn btn-primary"
                          :disabled="parkNotSelected"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12"
                    style="max-height: 300px; overflow-y: hidden"
                  >
                    <b-table
                      ref="table"
                      id="spotTable"
                      striped
                      :fields="fields"
                      :items="spots"
                      :filter="filter"
                      small
                      outlined
                      empty-text="No Spot Results..."
                      empty-filtered-text="No Spot Results..."
                      :filter-function="filterSpots"
                      sticky-header="300px"
                      no-border-collapse
                      selectable
                      show-empty
                      :busy="loadingSpots"
                      primary-key="id"
                      @filtered="onFilter"
                      @sort-changed="onSort"
                      no-select-on-click
                      bordered
                      sort-icon-left
                      :sort-null-last="true"
                    >
                      <template v-slot:table-busy>
                        <div class="text-center my-2">
                          <span
                            class="spinner-border spinner-border-sm mx-auto"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </div>
                      </template>
                      <template v-slot:cell(isSelected)="data">
                        <template v-if="data.rowSelected">
                          <i
                            @click="deselectSpot(data.item.id)"
                            class="align-middle fa-fw fa-check-circle fas text-primary ml-2"
                          ></i>
                          <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                          <i
                            @click="selectSpot(data.item.id)"
                            class="align-middle fa-fw fa-check-circle far ml-2"
                          ></i>
                          <span class="sr-only">Not selected</span>
                        </template>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
        <AdminClosureComments :closure="parentClosure" class="mt-4" />
        <AdminAuditHistory
          @getHistory="getHistory"
          :history="history"
          headerTitle="Closure History"
          v-if="parentClosure.id"
        />
        <div class="row">
          <div class="col-12">
            <button
              type="button"
              class="btn btn-primary mr-2 my-1"
              @click="onSubmit"
              :disabled="disableSaveButton"
            >
              SAVE
            </button>
            <button
              type="button"
              class="btn btn-secondary mr-2 my-1"
              @click="resetAllFields"
              v-show="!parentClosure.id"
            >
              CLEAR
            </button>
            <button
              type="button"
              class="btn btn-cancel mr-2 my-1"
              @click="goToClosureSearch"
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import DateInput from "@/validation/DateInput.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import AdminClosureService from "@/services/admin/AdminClosureService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import AdminClosureComments from "@/components/admin/AdminClosureComments";
import AdminAuditHistory from "@/components/admin/AdminAuditHistory";
import TextInput from "@/validation/TextInput.vue";

export default {
  name: "AdminLocationClosureForm",
  mixins: [checkPermissionMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    DateInput,
    Multiselect,
    FormErrorAlert,
    AdminClosureComments,
    AdminAuditHistory,
    TextInput
  },
  props: {
    closure: Object,
    title: String
  },
  data() {
    return {
      parentClosure: {},
      loading: false,
      previouslySelectedParksSpots: [],
      previouslySelectedParksSpotTypes: [],
      selectedParks: [],
      selectedRegions: [],
      selectedSpotTypes: [],
      closureTypes: [],
      regions: [],
      parks: [],
      loadingParks: false,
      spotTypes: [],
      spots: [],
      loadingSpots: false,
      errors: [],
      history: null,
      fields: [
        { key: "isSelected", label: "Selected", sortable: true },
        { key: "locationName", sortable: true },
        { key: "loopName", sortable: true },
        { key: "name", sortable: true },
        {
          key: "spotTypes",
          sortable: true,
          formatter: values => {
            return values.map(x => x.name).join(", ");
          },
          sortByFormatted: true,
          filterByFormatted: true
        }
      ],
      filter: "",
      disableSaveButton: false
    };
  },
  computed: {
    initialDate() {
      if (this.parentClosure.startDate) {
        return moment(this.parentClosure.startDate, "MM-DD-YYYY").format(
          "YYYY-MM-DD"
        );
      } else {
        return "";
      }
    },
    parkNotSelected() {
      return this.selectedParks.length === 0;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    selectedSpotIds() {
      return this.spots.filter(x => x.isSelected === true).map(y => y.id);
    },
    filteredItemsLength() {
      return this.$refs?.table?.sortedItems?.length ?? 0;
    },
    closedDayCount() {
      return (
        moment(this.parentClosure.endDate).diff(
          this.parentClosure.startDate,
          "days"
        ) + 1
      );
    },
    closedDayRecurringLimit() {
      const daysInYear = 365;
      const startDateYear = moment(this.parentClosure.startDate).year();
      const endDateYear = moment(this.parentClosure.endDate).year();
      if (
        (this.isLeap(startDateYear) &&
          moment(this.parentClosure.startDate).isSameOrBefore(
            new Date(startDateYear, 1, 29)
          )) ||
        (this.isLeap(endDateYear) &&
          moment(this.parentClosure.endDate).isSameOrAfter(
            new Date(endDateYear, 1, 29)
          ))
      ) {
        return daysInYear + 1;
      } else {
        return daysInYear;
      }
    },
    referenceId() {
      return this.parentClosure.id
        ? `C${(this.parentClosure.id + "").padStart(4, "0")}`
        : "";
    }
  },
  methods: {
    isLeap(year) {
      return new Date(year, 1, 29).getDate() === 29;
    },
    selectSpot(id) {
      this.spots = this.spots.map(x => {
        return { ...x, isSelected: x.id === id ? true : x.isSelected };
      });
      this.onSort();
    },
    deselectSpot(id) {
      this.spots = this.spots.map(x => {
        return { ...x, isSelected: x.id === id ? false : x.isSelected };
      });
      this.onSort();
    },
    filterSpots(data, filter) {
      if (
        data.isSelected ||
        data.name.toLowerCase().includes(filter.toLowerCase()) ||
        data.loopName.toLowerCase().includes(filter.toLowerCase()) ||
        data.locationName.toLowerCase().includes(filter.toLowerCase()) ||
        data.spotTypes
          .map(x => x.name)
          .join(", ")
          .toLowerCase()
          .includes(filter.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    },
    onSubmit() {
      if (
        this.closedDayCount > this.closedDayRecurringLimit &&
        this.parentClosure.isRecurring
      ) {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message:
            "Closure duration can not exceed 365 days when Repeat Annually is selected. Please reduce the closure duration for the closure.",
          layer: "admin"
        });
        return;
      }

      this.disableSaveButton = true;
      this.$refs.parkClosureForm.validate().then(success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.parkClosureForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.parkClosureForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
          this.disableSaveButton = false;
        } else {
          this.errors = [];
          if (
            this.selectedSpotIds.length !== 0 &&
            (this.parentClosure.includeSunday === true ||
              this.parentClosure.includeMonday === true ||
              this.parentClosure.includeTuesday === true ||
              this.parentClosure.includeWednesday === true ||
              this.parentClosure.includeThursday === true ||
              this.parentClosure.includeFriday === true ||
              this.parentClosure.includeSaturday === true)
          ) {
            let closureRequest = {
              id: this.parentClosure.id,
              startDate: this.parentClosure.startDate,
              endDate: this.parentClosure.endDate,
              closureTypeId: this.parentClosure.closureType.id,
              isRecurring: this.parentClosure.isRecurring,
              overrideAllowed: this.parentClosure.overrideAllowed ?? false,
              includeMonday: this.parentClosure.includeMonday,
              includeTuesday: this.parentClosure.includeTuesday,
              includeWednesday: this.parentClosure.includeWednesday,
              includeThursday: this.parentClosure.includeThursday,
              includeFriday: this.parentClosure.includeFriday,
              includeSaturday: this.parentClosure.includeSaturday,
              includeSunday: this.parentClosure.includeSunday,
              closureSpotIds: this.selectedSpotIds,
              comment: this.parentClosure.comment
            };
            this.$emit("submit", closureRequest);
          } else {
            window.scrollTo(0, 0);
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message:
                "You must select at least one spot and have at least one day of the week selected...",
              layer: "admin"
            });
            this.disableSaveButton = false;
          }
        }
      });
    },
    async getHistory() {
      const closureService = new AdminClosureService(this.tenantId);
      const response = await closureService.getClosureHistory(
        this.parentClosure.id
      );
      if (response?.statusCode == "Success") {
        this.history = response.data;
      } else {
        window.scrollTo(0, 0);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    onFilter() {
      // set timeout here specifically so it doesn't run after every character entry on filter
      setTimeout(() => {
        this.$refs.table.sortedItems.forEach((item, index) => {
          if (
            this.spots
              .filter(x => x.isSelected === true)
              .map(y => y.id)
              .includes(item.id)
          ) {
            this.$refs.table.selectRow(index);
          } else if (this.$refs.table.isRowSelected(index)) {
            this.$refs.table.unselectRow(index);
          }
        });
      }, 250);
    },
    onSort() {
      // same here for set timeout, have to give the table a sec to return the sorted items
      setTimeout(() => {
        this.$refs.table.sortedItems.forEach((item, index) => {
          if (
            this.spots
              .filter(x => x.isSelected === true)
              .map(y => y.id)
              .includes(item.id)
          ) {
            this.$refs.table.selectRow(index);
          } else if (this.$refs.table.isRowSelected(index)) {
            this.$refs.table.unselectRow(index);
          }
        });
      }, 100);
    },
    resetAllFields() {
      this.$refs.table.clearSelected();
      this.parentClosure.startDate = this.parentClosure.endDate = this.filter =
        "";
      this.parentClosure.isRecurring = false;
      this.parentClosure.closureType = null;
      this.parentClosure.includeSunday = this.parentClosure.includeMonday = this.parentClosure.includeTuesday = this.parentClosure.includeWednesday = this.parentClosure.includeThursday = this.parentClosure.includeFriday = this.parentClosure.includeSaturday = true;
      this.previouslySelectedParksSpotTypes = this.previouslySelectedParksSpots = this.selectedParks = this.selectedRegions = this.selectedSpotTypes = this.spots = this.errors = [];
      this.$nextTick(() => this.$refs.parkClosureForm.reset());
    },
    goToClosureSearch() {
      this.resetAllFields();
      this.$router.push("/admin/park-closure-search").catch(() => {});
    },
    minDepartureDate(ymd) {
      if (this.parentClosure.startDate) {
        return moment(this.parentClosure.startDate, "MM-DD-YYYY")
          .subtract(1, "days")
          .isSameOrAfter(ymd, "day");
      } else {
        return false;
      }
    },
    async filterParksAdd(selectedRegion) {
      if (this.parks.length === 0) {
        this.loadingParks = true;
        const parks = await this.getParks();
        this.parks = parks;
        this.selectedParks = [
          ...this.selectedParks,
          ...parks.filter(
            x =>
              x.regionId === selectedRegion.id &&
              !this.selectedParks.map(y => y.id).includes(x.id)
          )
        ];
        this.loadingParks = false;
      } else {
        const parks = this.parks;
        this.selectedParks = [
          ...this.selectedParks,
          ...parks.filter(
            x =>
              x.regionId === selectedRegion.id &&
              !this.selectedParks.map(y => y.id).includes(x.id)
          )
        ];
      }
      await this.openedSpots();
    },
    async filterParksRemove(removedRegion) {
      this.selectedParks = this.selectedParks.filter(
        x => x.regionId !== removedRegion.id
      );
      if (this.selectedParks.length === 0) {
        this.selectedSpotTypes = [];
        this.spots = [];
      }
      await this.openedSpots();
    },
    async selectAllParksAndRegions() {
      if (this.parks.length === 0) {
        this.loadingParks = true;
        const parks = await this.getParks();
        this.parks = parks;
        this.selectedParks = parks;
        this.loadingParks = false;
      } else {
        const parks = this.parks;
        this.selectedParks = parks;
      }
      const regions = this.regions;
      this.selectedRegions = regions;
      await this.openedSpots();
    },
    deselectAllParksAndRegions() {
      this.selectedParks = [];
      this.selectedSpotTypes = [];
      this.selectedRegions = [];
      this.spots = [];
    },
    filterSpotsAdd(addedSpotType) {
      this.$refs.table.sortedItems.forEach((x, index) => {
        if (
          !this.$refs.table.isRowSelected(index) &&
          x.spotTypes.some(y => y.id === addedSpotType.id)
        ) {
          this.spots = this.spots.map(z => {
            return { ...z, isSelected: z.id === x.id ? true : z.isSelected };
          });
        }
      });
      this.onSort();
    },
    filterSpotsRemove(removedSpotType) {
      this.$refs.table.sortedItems.forEach((x, index) => {
        if (
          this.$refs.table.isRowSelected(index) &&
          x.spotTypes.some(y => y.id === removedSpotType.id) &&
          !x.spotTypes
            .filter(n => n.id !== removedSpotType.id)
            .some(a => this.selectedSpotTypes.map(b => b.id).includes(a.id))
        ) {
          this.spots = this.spots.map(z => {
            return { ...z, isSelected: z.id === x.id ? false : z.isSelected };
          });
        }
      });
      if (this.selectedSpotTypes.length === 0) {
        this.spots = this.spots.map(m => {
          return { ...m, isSelected: false };
        });
      }
      this.onSort();
    },
    selectAllSpotsAndSpotTypes() {
      this.spots = this.spots.map(x => {
        return { ...x, isSelected: true };
      });
      this.onSort();
    },
    deselectAllSpotsAndSpotTypes() {
      this.selectedSpotTypes = [];
      this.spots = this.spots.map(x => {
        return { ...x, isSelected: false };
      });
      this.onSort();
    },
    async getParks() {
      const lookupService = new AdminLookupService(this.tenantId);
      const locations = await lookupService.getLocations(this.tenantId);
      return locations.filter(loc =>
        this.checkLocationPermission("ParkManagementParkClosure", loc.id)
      );
    },
    async openedParks() {
      if (this.parks.length === 0) {
        this.loadingParks = true;
        this.parks = await this.getParks();
        this.loadingParks = false;
      }
    },
    async getRegions() {
      const lookupService = new AdminLookupService(this.tenantId);
      const response = await lookupService.getRegions();
      this.regions = response;
    },
    async getClosureTypes() {
      const closureService = new AdminClosureService(this.tenantId);
      const response = await closureService.getClosureTypes();
      this.closureTypes = response.data;
    },
    async openedSpotTypes() {
      const oldList = this.previouslySelectedParksSpotTypes;
      const newList = this.selectedParks;
      const same = this.testParkListEquality(oldList, newList);
      if (this.spotTypes.length == 0 || !same) {
        this.previouslySelectedParksSpotTypes = newList;
        this.spotTypes = this.getSpotTypes();
      }
    },
    getSpotTypes() {
      let spotTypes = [];
      this.spots.forEach(x => {
        spotTypes = [...spotTypes, ...x.spotTypes];
      });
      return spotTypes
        .filter(
          (value, index, self) => self.map(x => x.id).indexOf(value.id) == index
        )
        .sort((a, b) =>
          a.name.trim() > b.name.trim()
            ? 1
            : a.name.trim() < b.name.trim()
            ? -1
            : 0
        );
    },
    async openedSpots() {
      const oldList = this.previouslySelectedParksSpots;
      const newList = this.selectedParks;
      const same = this.testParkListEquality(oldList, newList);
      if (this.spots.length == 0 || !same) {
        this.previouslySelectedParksSpots = newList;
        this.selectedSpotTypes = [];
        this.loadingSpots = true;
        let spots = await this.getSpots(this.selectedParks.map(x => x.id));
        this.spots = spots.map(x => {
          return { ...x, isSelected: this.selectedSpotIds.includes(x.id) };
        });
        this.loadingSpots = false;
        this.onSort();
      }
    },
    async getSpots(ids) {
      const lookupService = new AdminLookupService(this.tenantId);
      return await lookupService.getSpotsForLocations(ids);
    },
    testParkListEquality(oldList, newList) {
      if (oldList.length === newList.length) {
        newList.forEach(x => {
          const inc = oldList.some(y => y.id == x.id);
          if (inc === false) {
            return false;
          }
        });
        return true;
      } else {
        return false;
      }
    },
    async initialize() {
      this.loading = true;
      await this.getRegions();
      await this.getClosureTypes();
      if (!this.parentClosure.id) {
        this.loading = false;
        return;
      }
      this.parentClosure.startDate = moment(
        this.parentClosure.startDate,
        "MM/DD/YYYY"
      ).format("MM/DD/YYYY");
      this.parentClosure.endDate = moment(
        this.parentClosure.endDate,
        "MM/DD/YYYY"
      ).format("MM/DD/YYYY");
      this.parentClosure.closureType = this.closureTypes.find(
        x => x.id === this.parentClosure.closureTypeId
      );
      this.parks = await this.getParks();
      this.selectedParks = this.parks.filter(x =>
        this.parentClosure.closureSpots.map(y => y.locationId).includes(x.id)
      );
      let spots = await this.getSpots(this.selectedParks.map(x => x.id));
      this.spots = spots.map(z => {
        return {
          ...z,
          isSelected: this.parentClosure.closureSpots
            .map(y => y.spotId)
            .includes(z.id)
        };
      });
      this.onSort();
      this.loading = false;
    }
  },
  watch: {
    closure() {
      this.parentClosure = JSON.parse(JSON.stringify(this.closure));
    }
  },
  async mounted() {
    this.parentClosure = JSON.parse(JSON.stringify(this.closure));
    await this.initialize();
    this.$refs.closureRegions.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.closureParks.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.closureType.$refs.search.setAttribute("autocomplete", "off");
    this.$refs.closureSpotTypes.$refs.search.setAttribute(
      "autocomplete",
      "off"
    );
  }
};
</script>

<style scoped>
.grow {
  flex-grow: 1;
}
.list-item {
  border: 2px solid #eee;
}
</style>
